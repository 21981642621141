import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';

// Components
import NotFound from 'components/NotFound';
import Loader from 'components/Loader';

// Layouts
import AppLayout from 'layouts/app';

// Routes
import appRoutes from 'routes';

// Utils
import { REDIRECT_URLS, CASINO_REDIRECT_URLS } from './utils/redirectUrls';

const App = (props) => {
  return (
    <Router>
      <Suspense fallback={() => <Loader />}>
        <AppLayouts {...props} />
      </Suspense>
    </Router>
  );
}

export default App;

const AppLayouts = (props) => {
  let Layout = AppLayout;
  let routes = appRoutes;

  {/* Removes trailing slashes */}
  let path = window.location.pathname;
  if (path.substr(path.length - 1) === '/' && path !== '/') path = path.slice(0, -1);

  // if (_.sum(_.map(appRoutes, r => r.layout && path === r.path && r))) {
  //   Layout = AppLayout;
  //   routes = _.compact(_.map(appRoutes, r => r.layout && path === r.path && r));
  // } else if (_.sum(_.map(appRoutes, r => !r.layout && path === r.path && r))) {
  //   routes = _.compact(_.map(appRoutes, r => !r.layout && path === r.path && r));
  // }

  if (Layout) {
    return <Layout {...props} routes={routes} />
  } else if (routes && routes.length > 0) {
    return (
      <Switch>
        {/* Removes trailing slashes */}
        <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
        {/* Removes duplicate slashes in the middle of the URL */}
        <Route
          path="/:url(.*//+.*)"
          exact
          strict
          render={({ match }) => (
            <Redirect to={`/${match.params.url.replace(/\/\/+/, "/")}`} />
          )}
        />

        {/* Redirections */}
        <Redirect from="/?p=12340" to={'/'} />

        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            render={props => <route.component {...props} />}
            exact={route.exact}
          />
        ))}
      </Switch>
    );
  }
  
  const redirect = REDIRECT_URLS[path];
  if (redirect) return window.location.href = redirect;

  const casinoRedirect = CASINO_REDIRECT_URLS.includes(window.location.href) || CASINO_REDIRECT_URLS.includes(window.location.href.slice(0, -1));
  if (casinoRedirect) return window.location.href = "https://youtube.com";

  return <Route path={'*'} component={NotFound} />
};
