import React, { useState, useEffect } from 'react';
import WOW from 'wowjs';

// Components
import Header from 'components/Header';
import Footer from 'components/Footer';

// Layout
import LayoutRoutes from 'components/LayoutRoutes';
import Drawer from 'components/Drawer';
import BlackFridayBanner from 'components/BlackFridayBanner';

const AppLayout = props => {
  const { routes } = props;

  const [drawerShow, setDrawerShow] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  
  return (
    <div className="wrapper">
      {/* Header */}
      <Header drawerShow={drawerShow} setDrawerShow={setDrawerShow} />
      {/* Header End */}

      {/* Drawer */}
      <Drawer drawerShow={drawerShow} setDrawerShow={setDrawerShow} />
      {/* Drawer End */}

      {/* Main Content */}
      <main className="main-content">

        {/* Layout Routes Or Pages To Render */}
        <LayoutRoutes {...props} routes={routes} />
        {/* Layout Routes Or Pages To Render End */}

      </main>
      {/* Main Content End */}

      {/* Footer */}
      <Footer />
      {/* Footer End */}

      <BlackFridayBanner />
    </div>
  )
};

export default AppLayout;
