import { lazy } from 'react';

// Layouts
import AppLayout from 'layouts/app';

// Components
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const PartnerWithUs = lazy(() => import("./pages/PartnerWithUs"));
const Whitelabel = lazy(() => import("./pages/Whitelabel"));

// New Pages
const NewHome = lazy(() => import("./pages/NewHome"));
const Pricing = lazy(() => import("./pages/Pricing"));
const BF24 = lazy(() => import("./pages/BF24"));
const LinkedinOutreach = lazy(() => import("./pages/LinkedinOutreach"));
const SmartSequences = lazy(() => import("./pages/SmartSequences"));
const Personalization = lazy(() => import("./pages/Personalization"));
const EmailOutreach = lazy(() => import("./pages/EmailOutreach"));
const Agency = lazy(() => import("./pages/Agency"));
const Affiliate = lazy(() => import("./pages/Affiliate"));
const Discovery = lazy(() => import("./pages/Discovery"));
const Faqs = lazy(() => import("./pages/Faq"));
const CaseStudies = lazy(() => import("./pages/CaseStudies"));
const CaseStudyShow = lazy(() => import("./pages/CaseStudyShow"));
const LinkedCampAlternative = lazy(() => import("./pages/LinkedCampAlternative"));
const AlternativeInner = lazy(() => import("./pages/AlternativeInner"));
const Features = lazy(() => import("./pages/Features"));

const routes = [
  {
    path: '/',
    component: NewHome,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/terms',
    component: TermsOfUse,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/partners',
    component: PartnerWithUs,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/faqs',
    component: Faqs,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/affiliates',
    component: Affiliate,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/whitelabel',
    component: Whitelabel,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/pricing',
    component: Pricing,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/linkedin-outreach',
    component: LinkedinOutreach,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/smart-sequences',
    component: SmartSequences,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/image-gif-personalization',
    component: Personalization,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/email-outreach',
    component: EmailOutreach,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/agency',
    component: Agency,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/discovery',
    component: Discovery,
    layout: AppLayout,
    exact: true,
  },
  // {
  //   path: '/linkedcamp-alternatives',
  //   component: LinkedCampAlternative,
  //   layout: AppLayout,
  //   exact: true,
  // },
  // {
  //   path: '/linkedcamp-alternatives/expandi',
  //   component: AlternativeInner,
  //   layout: AppLayout,
  //   exact: true,
  // },
  {
    path: '/features',
    component: Features,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/success-stories',
    component: CaseStudies,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/success-stories/:name',
    component: CaseStudyShow,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/black-friday-2024',
    component: BF24,
    layout: AppLayout,
    exact: true,
  },
];

export default routes;
