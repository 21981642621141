/**
 *
 * Linkedcamp
 * Author: Linkedcamp
 * Email: support@linkedcamp.com
 * Website: linkedcamp.com
 * Version: 1.0
 * Date: Thu Oct 07 2021
 * Category: Pages
 * Title: NotFound
 *
 */

import React from 'react';
import { Button } from 'reactstrap';

const NotFound = (props) => (
  <div className="not-found">
    <div className="text-center error-page pd-30">
      <br/> <br/>
      <h4 className="page-title"><strong>404</strong> Page Not Found</h4>
      <figure className="error-img">
        <img src="https://d2h9jghou9rbg3.cloudfront.net/v2/404.png" alt="404" height={500} />
      </figure>
      <p>You may have mistyped the address or the page may have moved.</p>
      <Button color="primary" size='lg' onClick={() => window.location.href = "/"}>Back to Home</Button>
    </div>
  </div>
);

export default NotFound;
