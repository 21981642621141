import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const BlackFridayBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const history = useHistory();

  const styles = {
    banner: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'black',
      color: 'white',
      padding: '16px',
      boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 50
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    leftContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px'
    },
    blackFridayText: {
      color: '#FFD700',
      fontWeight: 'bold',
      fontSize: '20px',
      '@media (max-width: 768px)': {
        display: 'none'
      }
    },
    mainContent: {
      flex: 1,
      color: '#fffff',
      textAlign: 'center',
      '@media (min-width: 768px)': {
        textAlign: 'left'
      }
    },
    mobileBlackFriday: {
      color: '#FFD700',
      fontWeight: 'bold',
      marginRight: '8px',
      '@media (min-width: 768px)': {
        display: 'none'
      }
    },
    promoCode: {
      backgroundColor: '#FFD700',
      color: 'black',
      padding: '4px 8px',
      borderRadius: '4px',
      fontWeight: 'bold'
    },
    subtext: {
      fontSize: '14px',
      color: '#cccccc',
      marginTop: '4px'
    },
    rightContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px'
    },
    shopButton: {
      backgroundColor: '#FFD700',
      color: 'black',
      fontWeight: 'bold',
      padding: '8px 16px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      ':hover': {
        backgroundColor: '#FFE44D'
      }
    },
    closeButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'white',
      cursor: 'pointer',
      padding: '4px',
      fontSize: 24,
      ':hover': {
        color: 'white'
      }
    }
  };

  if (!isVisible) return null;

  return (
    <div style={styles.banner}>
      <div style={styles.container}>
        <div style={styles.leftContent}>
          {window.innerWidth > 1024 && 
            <div style={styles.blackFridayText}>
              BLACK FRIDAY
            </div>
          }
            
          <div style={styles.mainContent}>
            {/* <p style={{ fontWeight: 500, color: 'white' }}>
              Save up to 40% off! Use code{' '}
              <span style={styles.promoCode}>BFY24</span>
              {' '}for yearly plans & Use code{' '}
              <span style={styles.promoCode}>BFM24</span>
              {' '}for monthly plans and get 20% off
            </p>
            <p style={styles.subtext}>
              Limited time offer! Ends on midnight 30th November
            </p> */}
            <p style={{ fontWeight: 500, color: 'white', margin: 0 }}>
              Get up to 40% off on Linkedcamp during November with our Black Friday Deal.
            </p>
          </div>
        </div>
        
        <div style={styles.rightContent}>
          <button 
            style={styles.shopButton}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = '#FFE44D';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = '#FFD700';
            }}
            onClick={() => history.push("/black-friday-2024")}
          >
            Learn More
          </button>
          
          <button 
            onClick={() => setIsVisible(false)}
            style={styles.closeButton}
            aria-label="Close banner"
          >
           X
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlackFridayBanner;
