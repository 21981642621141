import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const LayoutRoutes = ({ routes, getCurrentUser, ...layoutProps }) => (
  <Switch>
    {/* Removes trailing slashes */}
    <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />

    {/* Redirections */}
    <Redirect preserveQueryString={false} from="/?p=12340" to={'/'} />

    {
      routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          render={props => <route.component getCurrentUser={getCurrentUser} {...layoutProps} {...props} />}
          exact={route.exact}
          strict
        />
      ))
    }
  </Switch>
);

export default LayoutRoutes;
