/**
 *
 * Linkedcamp
 * Author: Linkedcamp
 * Email: support@linkedcamp.com
 * Website: linkedcamp.com
 * Version: 1.0
 * Date: Tue Oct 12 2021
 * Category: Pages
 * Title: Drawer
 *
 */

import React, { useState } from 'react';
import { Button } from 'reactstrap';

// styles
import './drawer.css';

const Drawer = ({ drawerShow, setDrawerShow }) => {
  const [dropdownShow, setDropdownShow] = useState(false);
  const [dropdownShowOne, setDropdownShowOne] = useState(false);

  return (
    <div className={`drawer ${drawerShow ? 'show-drawer' : ''}`}>
      <div className="drawer-inner">
        <a className="close-drawer-icon" onClick={() => setDrawerShow(!drawerShow)}>
          <svg height="24" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24">
            <line x1="3" x2="21" y1="12" y2="12"/><line x1="3" x2="21" y1="6" y2="6"/><line x1="3" x2="21" y1="18" y2="18"/>
          </svg>
        </a>
        <ul className="main-menu">
          <SolutionsMenu dropdownShow={dropdownShow} setDropdownShow={setDropdownShow} />
          <li>
            <a href="/pricing" rel="nooppener noreferrer">Pricing</a>
          </li>
          {/* <li>
            <a href="/case-study" rel="nooppener noreferrer">Case Studies</a>
          </li> */}
          <PartnersMenu dropdownShowOne={dropdownShowOne} setDropdownShowOne={setDropdownShowOne} />
          <li>
            <a href="https://linkedcamp.com/blog" rel="nooppener noreferrer">Blog</a>
          </li>
          <li>
            <a href="/faqs" rel="nooppener noreferrer">FAQ</a>
          </li>
          <li>
            <Button color="secondary" onClick={() => window.open('/discovery')}>Discovery</Button>
          </li>
          <li>
            <Button color="primary" onClick={() => window.open("https://app.linkedcamp.com/register", "_blank")}>14 Days Free Trial</Button>
          </li>
          <li>
            <Button type="success" onClick={() => window.open('https://app.linkedcamp.com')}>Login</Button>
          </li>
        </ul>
      </div>
    </div>
  )
};

Drawer.propTypes = {};

export default Drawer;

const SolutionsMenu = ({ dropdownShow, setDropdownShow }) => (
  <li className="menu-dropdown-item">
    <span onClick={() => setDropdownShow(!dropdownShow)}>Solutions <img src="/images/arrow.png" alt="" className="dropdown-icon" /></span>
    <div className={`dropdown-content ${dropdownShow ? 'show-menu-dropdown' : ''}`}>
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="/linkedin-outreach" className="title">
            LinkedIn Outreach
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Improve your LinkedIn outreach and generate hot leads.</p>
        </div>
        <div className="dropdown-content-item">
          <a href="/image-gif-personalization" className="title">
            Image gif personalization
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Enhance your engagement rates with our Image and GIF personalization.</p>
        </div>
      </div>
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="/smart-sequences" className="title">
            Smart Sequences
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Better connect with your prospects and turn them into lasting customers.</p>
        </div>
        <div className="dropdown-content-item">
          <a href="/email-outreach" className="title">
            Email Outreach
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Boost your email outreach efforts and generate 3x higher results.</p>
        </div>
      </div>
      <div className="dropdown-content-wrap">
        <figure className="img-wrap">
          <img src="/images/logo-rocket.png" alt="" />
        </figure>
      </div>
    </div>
  </li>
)

const PartnersMenu = ({ dropdownShowOne, setDropdownShowOne }) => (
  <li className="menu-dropdown-item partners-item">
    <span onClick={() => setDropdownShowOne(!dropdownShowOne)}>Partners <img src="/images/arrow.png" alt="" className="dropdown-icon" /></span>
    <div className={`dropdown-content ${dropdownShowOne ? 'show-menu-dropdown' : ''}`}>
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="/agency" className="title">
            Agency
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Our multi-channel solutions help your agency grow</p>
        </div>
      </div>
      <div className="dropdown-content-wrap">
        <div className="dropdown-content-item">
          <a href="/affiliates" className="title">
            Affiliates
            <img src="/images/rocket-icon-1.png" alt="" className="icon" />
            <img src="/images/rocket-color-icon.png" alt="" className="icon-hover" />
          </a>
          <p>Gain massive revenue by becoming our affiliate</p>
        </div>
      </div>
    </div>
  </li>
);
