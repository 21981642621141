/**
 *
 * Linkedcamp
 * Author: Linkedcamp
 * Email: support@linkedcamp.com
 * Website: linkedcamp.com
 * Version: 1.0
 * Date: Thu Oct 07 2021
 * Category: Pages
 * Title: Loader
 *
 */

import React from 'react';

const Loader = (props) => (
  <div className="loader">Loading...</div>
);

export default Loader;
