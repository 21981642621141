/**
 *
 * Linkedcamp
 * Author: Linkedcamp
 * Email: support@linkedcamp.com
 * Website: linkedcamp.com
 * Version: 1.0
 * Date: Thu Sep 30 2021
 * Category: Pages
 * Title: Footer
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap'; 

// styles
import './footer.css';

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="footer-upper-content">
        <Container>
          <Row>
            <Col xs="12" lg="5">
              <div className="footer-logo-wrap">
                <img src="/images/footer-logo.png" />
                {/* <div className="copy-right-text">
                  Copyright by <br />
                </div> */}
              </div>
              <p>The most powerful LinkedIn automation tool for sales teams & marketing agencies.</p>
              <ul className="footer-contact-list">
                <li>
                  <a href="mailto:support@linkedcamp.com" target="_blank">
                    <span className="icon">
                      <img src="/images/footer-img-2.png" alt="" />
                    </span>
                    <span>support@linkedcamp.com</span>
                  </a>
                </li>
              </ul>
              <ul className="footer-social-list">
                <li>
                  <a href="https://twitter.com/linkedcamp" target="_blank">
                    <svg viewBox="0 0 15 12" fill="currentColor" version="1.1"><path d="M4.71737 12C10.378 12 13.4736 7.38369 13.4736 3.38083C13.4736 3.24967 13.4709 3.11919 13.4647 2.98921C14.0656 2.56165 14.588 2.02796 15 1.42071C14.4486 1.66187 13.8552 1.82426 13.2327 1.89748C13.868 1.52248 14.356 0.929006 14.5861 0.221672C13.9914 0.56879 13.3329 0.821025 12.632 0.957051C12.0702 0.368277 11.2704 0 10.3855 0C8.68578 0 7.30747 1.35673 7.30747 3.02901C7.30747 3.2668 7.3346 3.49788 7.38715 3.71972C4.82962 3.59327 2.56162 2.38767 1.04392 0.554851C0.779318 1.00239 0.626969 1.52265 0.626969 2.07733C0.626969 3.12826 1.17034 4.05609 1.99658 4.59902C1.49176 4.58374 1.01748 4.44704 0.602743 4.21999C0.602061 4.23259 0.602061 4.24535 0.602061 4.25862C0.602061 5.72585 1.66305 6.95075 3.07104 7.22835C2.81257 7.2977 2.54063 7.33499 2.25982 7.33499C2.06158 7.33499 1.86879 7.3155 1.6813 7.28041C2.07301 8.48382 3.20923 9.35975 4.55632 9.38444C3.50284 10.1971 2.17588 10.6812 0.734108 10.6812C0.48588 10.6812 0.240893 10.6673 0 10.6392C1.36193 11.4984 2.97908 12 4.71737 12Z"></path></svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/linkedcamp" target="_blank">
                    <svg className="sc-imgww4-0 krBcwV sc-99mwp2-0 ditSSH facebook" viewBox="0 0 23 23" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M9.33473 8.3061H6.83473V11.9728H9.33473V22.9728H13.5014V11.9728H16.5014L16.8347 8.3061H13.5014V6.74776C13.5014 5.92276 13.6681 5.5561 14.4181 5.5561H16.8347V0.972763H13.6681C10.6681 0.972763 9.33473 2.43943 9.33473 5.18943V8.3061Z"></path></svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/linkedcamp" target="_blank">
                    <svg className="sc-imgww4-0 krBcwV sc-1hh4k8s-0 bnXIVG instagram" viewBox="0 0 23 23" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 2.10833C14.5667 2.10833 14.95 2.10833 16.1958 2.20417C19.3583 2.3 20.7958 3.83333 20.8917 6.9C20.9875 8.14583 20.9875 8.43333 20.9875 11.5C20.9875 14.5667 20.9875 14.95 20.8917 16.1C20.7958 19.1667 19.2625 20.7 16.1958 20.7958C14.95 20.8917 14.6625 20.8917 11.5 20.8917C8.43333 20.8917 8.05 20.8917 6.9 20.7958C3.7375 20.7 2.3 19.1667 2.20417 16.1C2.10833 14.8542 2.10833 14.5667 2.10833 11.5C2.10833 8.43333 2.10833 8.05 2.20417 6.9C2.3 3.83333 3.83333 2.3 6.9 2.20417C8.05 2.10833 8.43333 2.10833 11.5 2.10833ZM11.5 0C8.3375 0 7.95417 0 6.80417 0.0958333C2.5875 0.2875 0.2875 2.5875 0.0958333 6.80417C0 7.95417 0 8.3375 0 11.5C0 14.6625 0 15.0458 0.0958333 16.1958C0.2875 20.4125 2.5875 22.7125 6.80417 22.9042C7.95417 23 8.3375 23 11.5 23C14.6625 23 15.0458 23 16.1958 22.9042C20.4125 22.7125 22.7125 20.4125 22.9042 16.1958C23 15.0458 23 14.6625 23 11.5C23 8.3375 23 7.95417 22.9042 6.80417C22.7125 2.5875 20.4125 0.2875 16.1958 0.0958333C15.0458 0 14.6625 0 11.5 0ZM11.5 5.55833C8.24167 5.55833 5.55833 8.24167 5.55833 11.5C5.55833 14.7583 8.24167 17.4417 11.5 17.4417C14.7583 17.4417 17.4417 14.7583 17.4417 11.5C17.4417 8.24167 14.7583 5.55833 11.5 5.55833ZM11.5 15.3333C9.39167 15.3333 7.66667 13.6083 7.66667 11.5C7.66667 9.39167 9.39167 7.66667 11.5 7.66667C13.6083 7.66667 15.3333 9.39167 15.3333 11.5C15.3333 13.6083 13.6083 15.3333 11.5 15.3333ZM17.6333 4.025C16.8667 4.025 16.2917 4.6 16.2917 5.36667C16.2917 6.13333 16.8667 6.70833 17.6333 6.70833C18.4 6.70833 18.975 6.13333 18.975 5.36667C18.975 4.6 18.4 4.025 17.6333 4.025Z"></path></svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@linkedcamp/videos" target="_blank">
                    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="500px" height="500px"><path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"/></svg>
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs="12" lg="4">
              <div className="footer-link-col">
                <h3 className="title">Guides</h3>
                <ul>
                  <li><a href="https://linkedcamp.com/blog/the-ultimate-guide-to-linkedin-lead-generation/" target="_blank">Guide to LinkedIn Lead Generation </a></li>
                  <li><a href="https://linkedcamp.com/blog/linkedin-social-selling/" target="_blank">LinkedIn Social Selling</a></li>
                  <li><a href="https://linkedcamp.com/blog/how-to-use-linkedin-sales-navigator/" target="_blank">How to use LinkedIn Sales Navigator</a></li>
                  <li><a href="https://linkedcamp.com/blog/linkedin-social-selling-index-score/" target="_blank">Improve your LinkedIn SSI Score to 70+</a></li>
                  <li><a href="https://linkedcamp.com/blog/linkedin-weekly-invitation-limits/" target="_blank">LinkedIn Weekly Invotation Limits</a></li>
                  <li><a href="https://linkedcamp.com/blog/hyper-personalized-campaign/" target="_blank">Hyper Personalization</a></li>
                  <li><a href="https://linkedcamp.com/blog/cloud-based-linkedin-automation/" target="_blank">Cloud Based LinkedIn Automation</a></li>
                </ul>
              </div>
            </Col>
            <Col xs="12" lg="3">
              <div className="footer-link-col">
                <h3 className="title">Company</h3>
                <ul>
                  {/* <li><a href="/linkedin-outreach">LinkedIn Outreach</a></li>
                  <li><a href="/email-outreach">Email Outreach</a></li>
                  <li><a href="/smart-sequences">Smart Sequences</a></li>
                  <li><a href="/image-gif-personalization">Personalization</a></li> */}
                  <li><a href="https://app.linkedcamp.com/register">Sign Up</a></li>
                  {/* <li><a href="/discovery">Request a demo</a></li> */}
                  <li><a href="https://help.linkedcamp.com">Help Center</a></li>
                  <li><a href="/agency">Agencies</a></li>
                  <li><a href="/affiliates">Affiliates</a></li>
                  <li><a href="https://api.linkedcamp.com" target="_blank">API</a></li>
                  <li><a href="https://rm.linkedcamp.com/linkedcamp/roadmap" target="_blank">Roadmap</a></li>
                  <li><a href="/terms">Terms of Use</a></li>
                  <li><a href="/privacy-policy">Privacy Policy</a></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <br/><br/>
        <Row className='flex'>
          <small className='text-white text-center'>LinkedCamp is not an official product published by LinkedIn. All LinkedIn ™ logos and trademarks used and displayed are the property of LinkedIn. Use Linkedcamp at your own risk.</small>
        </Row>
      </div>
    </footer>
  )
};

export default Footer;
